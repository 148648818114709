body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin-top: 4em;
  margin-bottom: 4em;
}
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .form-control,
.loading-skeleton .block {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}

.skeleton-row {
  background: #fff;
  border: none;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
  padding: 1em;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 0.65em;
}

.xs .skeleton-row {
  box-shadow: none;
  padding: 0em;
  height: 25px;
}

.xs .skeleton-row .block {
  font-size: 5px;
}

.lg .skeleton-row {
  box-shadow: none;
  padding: 0em;
  height: 80px;
}

.lg .skeleton-row .block {
  font-size: 3em;
}

.compact-table-loading .skeleton-row {
  padding: 0em;
  height: 35px;
}

.skeleton-row .block {
  font-size: x-small;
}

.compact-table-loading .skeleton-row .block {
  font-size: 8px;
}

.skeleton-text {
  background: #fff;
  border: none;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 0.65em;
}

.skeleton-text .block {
  font-size: 10px;
}

.lg-table-col {
  min-width: 335px;
}

.xl-table-col {
  min-width: 350px;
}

.btn:disabled.dropdown-toggle {
  border: none;
}

.collection-type-container .card .card {
  box-shadow: none !important;
}
.collection-type-container .card {
  box-shadow: none !important;
}

.collection-type-container .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  border-bottom: 1px solid rgba(36, 49, 65, 0.05) !important;
  box-shadow: none;
}

.collection-type-container .nk-tb-list.is-separate .nk-tb-head .nk-tb-col {
  padding: 6px 0 !important;
}

.gallery .image-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #2c3782;
  border-radius: 0.25rem;
  font-size: 2.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.gallery .image-overlay .icon {
  color: #fff;
}

.gallery-content {
  position: relative;
}

.gallery-content:hover .image-overlay {
  visibility: visible;
  opacity: 0.8;
}

.table-empty {
  padding: 1em;
  font-weight: 500;
  text-align: center;
  position: relative;
  top: -12px;
  color: var(--bs-gray-500);
}

.child-form {
  background: #f9f9f9;
  padding: 2em;
  border: 1px solid #dbdfea;
  box-shadow: rgba(54, 74, 99, 0.05) 0px 1px 3px 0px;
}

.child-form h3 {
  display: inline-block;
  margin-right: 0.5em;
}

.child-form .item {
  padding: 2em 0em;
  border-bottom: 1px solid #dbdfea;
}

.child-form .item > div {
  margin-bottom: 1em;
}

.child-form .item div.option {
  padding-top: 2em;
}

.child-form .child-form {
  margin-top: 2em;
}

.child-form .child-form .content {
  display: flex;
  flex-direction: column;
}

.child-form .child-form h3 {
  font-size: large;
}

.child-form .child-form .item {
  display: flex;
  padding: 2em 1em;
  border-bottom: 1px solid #dbdfea;
}

.child-form .child-form .item div {
  flex: 3;
  padding: 0em 1em;
  align-self: center;
}

.child-form .child-form .item div.option {
  flex: 1;
  padding: 0em 1em;
  align-self: center;
}

.child-form .child-form .item div.option > button {
  top: 14px;
}

.child-form .item div.option > button.child-item-button {
  display: none;
}

editor-squiggler {
  display: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #854fff;
  background-color: #854fff;
  box-shadow: none;
}

h4.page-title {
  font-size: 1.35rem;
}

.slim-borders.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  box-shadow: none;
  border-bottom: 1px solid rgba(219, 223, 234, 0.9);
}

.slim-borders.nk-tb-list.is-separate .nk-tb-item:last-child > .nk-tb-col {
  box-shadow: none;
  border-bottom: none;
}

.slim-table-loading .skeleton-row {
  height: 34px !important;
}

.slim-table-loading .skeleton-row .block {
  font-size: 7px !important;
}

.text-right {
  text-align: right;
}

.modal-content .nk-files-view-grid .nk-file {
  width: calc(25% - 16px);
}

.image-control .custom-control-label.no-selectable::before {
  display: none !important;
}

.card-bordered {
  border: 1px solid #dbdfea;
}

.g-4:not(.row) {
  margin: -0.75rem;
}

.nk-wg1-action {
  margin-top: auto;
  border-top: 1px solid #e5e9f2;
}

.nk-help {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 768px) {
  .nk-help {
    flex-wrap: nowrap;
  }

  .nk-help-img {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .card-inner-lg .nk-help-img {
    margin-left: -0.5rem;
  }

  .nk-help-text {
    flex-grow: 1;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }

  .nk-help-action {
    width: auto;
    margin-top: 0;
    padding-left: 1.5rem;
  }
}

.nk-wg1-action .link {
  position: relative;
  display: block;
  line-height: 1.3;
  padding: 1.25rem;
  padding-right: 2.5rem;
  white-space: inherit;
}

@media (min-width: 576px) {
  .nk-block-head-lg {
    padding-bottom: 2.5rem;
  }

  .nk-wg1-action .link {
    padding: 1.5rem;
    padding-right: 2.75rem;
  }

  .nk-wg1-block {
    padding: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .nk-wg1-img {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .nk-wg1-block {
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.card-aside {
  min-height: 80vh;
}

.form-editor {
  min-height: 300px;
  padding: 2em;
}

.md-container ol li {
  list-style: auto;
  margin-left: 1.3rem;
}

.md-container ol {
  margin-bottom: 1em;
}

.nav-link.disabled {
  color: #a6aaad !important;
  font-weight: 500;
}

.rdw-embedded-modal,
.rdw-image-modal,
.rdw-option-wrapper,
.rdw-embedded-modal-btn,
.rdw-image-modal-btn,
.rdw-embedded-modal-link-input,
.rdw-image-modal-link-input,
.rdw-embedded-modal-size-input,
.rdw-image-modal-size-input {
  border-radius: 4px;
}

.rdw-option-wrapper {
  padding: 0.8rem 0.6rem;
}

.rdw-option-wrapper:hover,
.rdw-embedded-modal-btn:hover,
.rdw-image-modal-btn:hover {
  color: var(--bs-btn-hover-color);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: none;
}

.rdw-embedded-modal-btn:disabled,
.rdw-image-modal-btn:disabled {
  border: none;
}

.rdw-embedded-modal-btn-section:first-child,
.rdw-image-modal-btn-section:first-child {
  background-color: var(--bs-primary);
}

.rdw-embedded-modal-header-label,
.rdw-image-modal-header-label {
  background: var(--bs-link-color);
  border-color: var(--bs-link-hover-color);
}

.rdw-embedded-modal,
.rdw-image-modal {
  min-height: 206px;
  box-shadow: 0 0 1px 0 rgba(82, 100, 132, 0.2),
    0 0px 10.52px 0px rgba(28, 43, 70, 0.15);
}

.rdw-option-active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-gray-100);
  box-shadow: none;
}

/** File **/

.form-file-input,
.form-file-label,
.form-file-label::after {
  height: auto !important;
}

.form-file-label .badge {
  font-size: 0.8em;
}

.form-file-label::after {
  content: "Buscar" !important;
}

.nk-ecwg {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
